<template>
  <div>
    <!--desktop design-->
    <div
     
      class="py-12 bg-[#c82228] md:mt-[2rem] m-auto justify-center"
    >
      <section class="max-w-7xl mx-auto w-full min-h-[600px]">
        <div class="p-5 lg:p-0 md:flex flex-col gap-2">
          <div class="flex justify-center lg:m-4 xl:m-0 items-center">
            <div class="text-center">
              <h2
                class="text-white pb-2 md:text-[35px] text-center text-[24px] font-semibold"
              >
              Student Testimonials 
              </h2>
            </div>
          </div>

          <div
            class="flex flex-row flex-wrap gap-5 aluminai-carousel m-auto justify-center lg:m-4 xl:m-0 items-center m-auto justify-center"
          >
            <div
              class="lg:w-[290px] w-[100%] lg:h-[494px] relative items-center m-auto justify-center"
              v-for="data in aluminaiData"
              :key="data.id"
            >
              <template v-if="data.id == is_active_video">
                <iframe
                  width="290"
                  height="494"
                  :src="data.link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                  @play="handleVideoPlay" 
                  @pause="handleVideoPause"
                  class="items-center m-auto justify-center"
  ></iframe>
              </template>

              <template v-else>
                <div class="" @click="is_active_video = data.id">
                  <!-- <div
                    class="absolute bottom-0 w-[290px] h-full"
                    style="
                      background: transparent
                        linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
                        0% no-repeat padding-box;
                      box-shadow: 0px 1px 6px #00000029;
                      border-radius: 14px;
                      opacity: 1;
                      height: 150px;
                    "
                  ></div> -->
                  <img
                    :src="data.bannerImg"
                    alt="alumini"
                    class="h-[494px] rounded-2xl w-[290px] items-center m-auto justify-center"
                    loading="lazy"
                    decoding="async"
                  />
                  <div class="flex justify-center items-center">
                    <img
                      src="@/assets/SuccessStories/Watch/play.svg"
                      alt="alumini"
                      srcset=""
                      class="absolute top-[200px] md:top-[200px] w-[75px] h-[75px]"
                    />
                  </div>
                  <div class="flex justify-center">
                    <img
                      :src="data.smallImg"
                      alt="alumini"
                      class="rounded-full w-[120px] h-[120px] justify-center m-auto absolute bottom-[17%] border p-2 border-[#F8C300]"
                      loading="lazy"
                      decoding="async"
                    />

                    <p class="text-white absolute justify-center bottom-[20px]">
                      <span class="text-[20px] font-bold">
                        {{ data.name }}
                      </span>
                      <br />
                      {{ data.subject }}
                    </p>
                  </div>
                </div>
               
              </template>
              
            </div>
            
          </div>
          <div @click="openModal"> <div class="md:hidden mt-8">
      <div
        class="bg-white text-[#c82228] py-1 px-12 m-auto rounded-full font-bold text-md cursor-pointer justify-center "
      >
        Enquire Now
      </div>
    </div></div>

 
    <div class="Emodal" v-if="isModalOpen">
      <div
        class="Emodal-content z-50 rounded-md relative bottom-2 w-[90%] sm:w-[100%] 2xl:w-[20%] "
      >
        <span class="close" @click="closeModal">&times;</span>
        <EnquireAlumni />
      </div>
    </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import EnquireAlumni from "@/components/LPEnquire/EnquireAlumni.vue";

export default {
  name: "AluminaiView",
   components: {
    EnquireAlumni,
  },
  data() {
    return {
      isOpen: false,
      isModalOpen: false,
      is_active_video: "0",
      // isSmallScreenAlumini: false,
      // isLargeScreenAlumini: false,
      aluminaiData: [
        {
          id: 1,
          bannerImg: require("@/assets/alumni-lp/anusha-bikeneni-thumbnail.webp"),
          smallImg: require("@/assets/alumni-lp/anusha-bikeneni-thumbnail.webp"),
          name: "Anushika",
          // subject: "MBA",
          link: "https://api.vignanonline.com/media/Anushika_40656.mp4",
        },
        {
          id: 2,
          bannerImg: require("@/assets/alumni-lp/lakshman-thumbnail.webp"),
          smallImg: require("@/assets/alumni-lp/lakshman-thumbnail.webp"),
          name: "Lakshman",
          // subject: "MBA",
          link: "https://api.vignanonline.com/media/Lakshman_61848.mp4",
        },
        {
          id: 3,
          bannerImg: require("@/assets/alumni-lp/padmavati-thumbnail.webp"),
          smallImg: require("@/assets/alumni-lp/padmavati-thumbnail.webp"),
          name: "Padmavati",
          // subject: "MBA",
          link: "https://api.vignanonline.com/media/Padmavathi-video_39239.mp4",
        },
        {
          id: 4,
          bannerImg: require("@/assets/alumni-lp/pydi-reddy.webp"),
          smallImg: require("@/assets/alumni-lp/pydi-reddy.webp"),
          name: "Pydi Reddy",
          // subject: "MBA",
          link: "https://api.vignanonline.com/media/Pydi-Reddy_35508.mp4",
        },

          {
          id: 5,
          bannerImg: require("@/assets/alumni-lp/ravi-teja-thumbnail.webp"),
          smallImg: require("@/assets/alumni-lp/ravi-teja-thumbnail.webp"),
          name: "Ravi Teja",
          // subject: "MBA",
          link: "https://api.vignanonline.com/media/Ravi Teja_24741.mp4",
        },
        {
          id: 6,
          bannerImg: require("@/assets/alumni-lp/vijaylakshmi-thumbnail.webp"),
          smallImg: require("@/assets/alumni-lp/vijaylakshmi-thumbnail.webp"),
          name: "Vijaylakshmi",
          // subject: "MBA",
          link: "https://api.vignanonline.com/media/Vijaylakshmi_27981.mp4",
        },
      ],
    
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
 
  mounted() {
    $(`.aluminai-carousel`).slick({
      infinite: true,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: true,
      dotsClass: "slick-dots",
      prevArrow: false,
      nextArrow: false,
      responsive: [
        {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
      ],
    });
  },

};
</script>

<style>
.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 6px 0;
  list-style-type: none;
}
.slick-dots li {
  margin: 0 0.25rem;
}
.slick-dots button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  margin: 0px;
  border: none;
  border-radius: 100%;
  background-color: silver;
  text-indent: -9999px;
}
.slick-dots li.slick-active button {
  background-color: #ebd61a;
  width: 0.75rem;
  height: 0.75rem;
}
.Emodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.Emodal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.close {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 0px;
  right: 5px;
  color:#000;
}
</style>