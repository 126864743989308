<template>
  <div class="bg-[#e8e8e8]">
    <div class="max-w-7xl m-auto">
      <!--desktop desgin-->
      <div class="hidden lg:flex justify-around p-10 items-center">
        <div>
          <div class="text-3xl font-bold text-[#c82228]">45+ </div>
          <div class="text-xl pt-2 font-semibold">Years of academic excellence</div>
        </div>
        <div>
          <div class="text-3xl font-bold text-[#c82228]">24×7</div>
          <div class="text-xl pt-2 font-semibold">Student Support</div>
        </div>
        <div>
          <div class="text-3xl font-bold text-[#c82228]">10+</div>
          <div class="text-xl pt-2 font-semibold">Additional Advanced certificate course</div>
        </div>
      </div>
      <!--desktop desgin-->
      <!--Mob desgin-->
      <div class="lg:hidden relative">
        <div
          class="prev-rank z-10 cursor-pointer absolute bottom-[30%] sm:right-[2%] right-[10%]"
        >
          <img
            src="@/assets/icon-slider-prev.svg"
            class="md:hidden"
            width="30px"
            height="40px"
            alt=""
          />
        </div>
        <div class="flex slick_mob_elective items-center">
          <div class="" v-for="el in studentDetail" :key="el.id">
            <div class="flex flex-col gap-2 mx-auto py-8">
              <div class="text-3xl font-bold text-[#c82228]">
                {{ el.courseName }}
              </div>
              <div class="text-xl pt-2 font-semibold">{{ el.courseDetail }}</div>
            </div>
          </div>
        </div>
        <div
          class="next-rank z-10 cursor-pointer absolute bottom-[30%] sm:left-[2%] left-[10%]"
        >
          <img
            src="@/assets/icon-slider-next.svg"
            width="30px"
            height="40px"
            class="md:hidden"
            alt=""
          />
        </div>
      </div>
      <!--Mob desgin-->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "electiveView",
  data() {
    return {
      studentDetail: [
        {
          id: 1,
          courseName: "45+",
          courseDetail: "Years of academic excellence",
        },
        {
          id: 2,
          courseName: "24×7",
          courseDetail: "Student Support",
        },
        {
          id: 3,
          courseName: "10+",
          courseDetail: "Additional Advanced certificate course",
        },
      ],
    };
  },
  mounted() {
    $(`.slick_mob_elective`).slick({
      infinite: true,
      autoplay: true,
      nextArrow: $(".next-rank"),
      prevArrow: $(".prev-rank"),
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
    });
  },
};
</script>

<style scoped></style>
