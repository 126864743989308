<template>
  <section>
  <div class="">
    <div  @click="openModal" class="hidden md:block">
      <div
        class="bg-[#c82228] text-white w-max py-2 px-8 m-auto rounded-full font-semibold text-2xl cursor-pointer mt-12"
      >
        Enquire Now
      </div>
    </div>
  </div>
  </section>
  <section class="bg-gray-800">
     <div
        class="max-w-7xl mx-auto w-full font-semibold text-sm flex flex-col md:flex-row justify-center items-center mt-4 lg:gap-10 gap-3 py-4 text-white"
      >
    <div
          class="flex flex-wrap justify-center items-center md:w-[30%] gap-3"
        >
          <a href="tel:+918877661755">
            <img src="@/assets/icon-call.svg" alt=""
          /></a>
          <div class="min-h-[10px]">
            <a href="tel:+918877661755">
              <p class="mb-0">+91 8877661755</p>
            </a>
          </div>
        </div>

         <div
          class="flex flex-col justify-center items-center md:w-[40%] gap-3"
        >
  
    <div class="text-xs pt-2 m-auto font-semibold text-white">
      ©️ Copyright Vignan University. All Rights Reserved.
    </div>
        </div>

        <div
          class="flex flex-wrap justify-center items-center md:w-[30%] gap-3"
        >
          <a
            href="mailto:info@vignanonline.com"
            target="_blank"
          >
            <img src="@/assets/icon-message.svg" alt="Vignan Online"
          /></a>
          <div class="min-h-[10px]">
            <p class="mb-0">
              info@vignanonline.com
            </p>
          </div>
        </div>
 

    <!-- <div class="w-[30%]"><span class="flex flex-row items-center gap-0"><img class="w-[35px] h-[35px] hidden md:block" src="/img/phone.1b816e38.svg" alt="call"><a href="tel:+918877661755" class="w-full font-medium text-black px-3 py-2 text-[12px] md:text-2xl">+91-8877661755</a></span></div>
    <div class="w-[30%]">
    <div  @click="openModal" class="hidden md:block">
      <div
        class="bg-[#c82228] text-white py-2 px-12 w-max m-auto rounded-full font-bold text-xl cursor-pointer"
      >
        Enquire Now
      </div>
    </div>
    <div class="text-xs pt-4 m-auto font-semibold">
      ©️ Copyright Vignan University. All Rights Reserved.
    </div>
  </div>
  <div class="w-[40%]"><span class="flex flex-row items-center gap-0"><img class="w-[35px] h-[35px] hidden md:block" src="/img/phone.1b816e38.svg" alt="call"><a href="tel:+918877661755" class="w-full font-medium text-black px-3 py-2 text-[12px] md:text-2xl">+91-8877661755</a></span></div>
 -->

     <!-- Modal -->
     <div class="Emodal" v-if="isModalOpen">
      <div
        class="Emodal-content z-50 rounded-md relative bottom-2 w-[90%] md:w-[35%] 2xl:w-[20%]"
      >
        <span class="close" @click="closeModal">&times;</span>
        <EnquireAlumni />
      </div>
    </div>
  </div>
</section>
</template>


<script>
import EnquireAlumni from "@/components/LPEnquire/EnquireAlumni.vue";
export default {
  name: "FooterView",
  components: {
    EnquireAlumni,
  },

  data() {
    return {
      isOpen: false,
      isModalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  }
};
</script>

<style scoped>
.Emodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.Emodal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.close {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 0px;
  right: 5px;
}
</style>
