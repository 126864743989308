<template lang="">
    <div>
      <!-- Modal -->
      <div class="text-[13px] bg-white border-2 border-gray-300 w-[100%] md:w-full" v-if="showForm">
        <div class="m-auto bg-[#2a2a2a] text-white text-sm font-bold py-2">
            Check your eligibility
          </div>
        <div>
          <form
            class="space-y-3 p-4 font-normal"
            @submit="$emit('closeWidget')"
            @submit.prevent="enquireSubmit"
          >
            <input
              type="text"
              placeholder="Enter Name *"
              id="name"
              v-on:input="validateInput"
              class="p-2 rounded-md w-full border border-[#ccc]"
              required
            />
            <input
              type="email"
              placeholder="Enter Email Address *"
              id="email"
              class="p-2 rounded-md w-full border border-[#ccc]"
              required
            />
            <div class="flex flex-col gap-2">
              <div class="flex items-center justify-center">
                <select
                  v-model="selectedCountryCode"
                  @change="handleChange"
                  class="text-sm w-[83px] bg-white p-2 border border-[#ccc] rounded-md text-black"
                  required
                >
                  <option
                    v-for="country in countryCodes"
                    :key="country.code"
                    :value="country.code"
                  >
                    {{
                      selectedCountryCode === country.code
                        ? country.code
                        : `${country.code} ${country.name}`
                    }}
                  </option>
                </select>
                <input
                  type="tel"
                  placeholder="Mobile Number *"
                  id="phone"
                  maxlength="10"
                  @input="onPhoneNumberChange"
                  v-model="phoneNumber"
                  class="p-2 rounded-md w-full border border-[#ccc]"
                  ref="phone"
                  required
                />
              </div>
              <p v-if="phoneNumberError" class="text-red-500 text-sm">
                {{ phoneNumberError }}
              </p>
            </div>
            <div class="flex gap-4">
              <div
                v-if="selectedCountryCode === '+91-'"
                class="box-border text-[14px] leading-normal text-[#333] border border-gray-300 p-1 flex-1 focus-within:border-blue-500"
              >
                <select
                  name="state"
                  id="SelectState"
                  class="form-control widget_input w-full bg-white border-none focus:outline-none focus:ring-0"
                  v-model="selectedState"
                  @change="onStateChange"
                  required
                >
                  <option value="" disabled selected>Select State *</option>
                  <option
                    v-for="state in stateList"
                    :key="state.state"
                    :value="state.state"
                  >
                    {{ state.state }}
                  </option>
                </select>
              </div>
              <div
                v-if="selectedCountryCode === '+91-'"
                class="box-border text-[14px] leading-normal text-[#333] border border-gray-300 p-1 flex-1 focus-within:border-blue-500"
              >
                <select
                  name="city"
                  id="SelectCity"
                  class="form-control widget_input w-full bg-white border-none focus:outline-none focus:ring-0"
                  v-model="selectedCity"
                  :disabled="!cities.length"
                  required
                >
                  <option value="" disabled selected>Select City *</option>
                  <option v-for="city in cities" :key="city" :value="city">
                    {{ city }}
                  </option>
                </select>
              </div>
            </div>
            <div class="flex flex-row gap-4">
              <select
                id="program"
                v-model="selectedProgram"
                @change="updateElectiveOptions"
                class="p-2 rounded-md w-full border border-[#ccc] bg-white text-black"
                required
              >
                <option value="" disabled selected>Program*</option>
                <option value="MBA">MBA</option>
                <option value="MCA">MCA</option>
                <!-- <option value="BBA">BBA</option>
                <option value="BCA">BCA</option> -->
                <!-- Add more program options as needed -->
              </select>
  
              <select
                id="elective"
                v-model="selectedElective"
                class="p-2 rounded-md w-full border border-[#ccc] bg-white text-black"
              >
                <option value="" disabled selected>Elective*</option>
                <option
                  v-for="option in electiveOptions"
                  :value="option.value"
                  :key="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
            <input
              type="text"
              placeholder="Vignan enrollment number"
              id="enlname"
              v-model="alumni_enrollment_number"
              class="p-2 rounded-md w-full border border-[#ccc]"
              
            />
            <div class="flex gap-4 text-[11px] items-start text-left text-black">
              <input type="checkbox" checked />
              <span class="text-justify"
                >I authorize Vignan Online and its associates to contact me with
                updates & notifications via email, SMS, WhatsApp, and voice call.
                This consent will override any registration for DNC/NDNC.</span
              >
            </div>
            <button
              type="submit"
              class="w-full text-white font-semibold bg-[#d02829] hover:bg-[#ca9216] rounded-lg px-4 py-2 text-center"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div v-if="!showForm">
        <div class="popup">
          <div class="popup-content1">
            <div
              class="flex flex-col justify-center m-auto items-center gap-20 text-center z-50"
            >
              <div class="relative flex">
                <p class="mt-4 p-8 border bg-white border-gray-800">
                  Thank you! <br />
                  Your data already existed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
import axios from "axios";
import stateMixins from "@/mixins/stateMixins";
import countryCodes from "@/mixins/countryCodeMixins";
export default {
  name: "EnquireView",
  mixins: [stateMixins, countryCodes],

  data: () => ({
    showForm: true,
    selectedProgram: "",
    selectedElective: "",
    electiveOptions: [],
    utmSource: "",
    utmSourceCampaign: "",
    utmSourceMedium: "",
    utmAdgroupid: "",
    utmCampaignid: "",
    utmCreativeid: "",
    utmDevice: "",
    utmKeyword: "",
    utmMatchtype: "",
    utmNetwork: "",
    utmPlacement: "",
    utmGclid: "",
    widget_id: "",
    activeTab: "tab-1",
    selectedState: "",
    selectedCity: "",
    cities: [],
    errorStatus: false,
    message: "",
    phoneNumber: "",
    selectedCountryCode: "+91-",
    phoneNumberError: "",
    checkExistenceResponse: "",
    alumni_enrollment_number: "",
  }),

  created() {
    console.log(this.$route.path);
    this.widget_id = this.widget_prop_id;
    this.utmSource = this.$route.query.utm_source || "Organic Search";
    this.utmSourceCampaign = this.$route.query.utm_campaign || "website";
    this.utmSourceMedium = this.$route.query.utm_medium || "";
    this.utmAdgroupid = this.$route.query.utm_adgroupid || "";
    this.utmCampaignid = this.$route.query.utm_campaignid || "";
    this.utmCreativeid = this.$route.query.utm_creativeid || "";
    this.utmDevice = this.$route.query.utm_device || "";
    this.utmKeyword = this.$route.query.utm_keyword || "";
    this.utmMatchtype = this.$route.query.utm_matchtype || "";
    this.utmNetwork = this.$route.query.utm_network || "";
    this.utmPlacement = this.$route.query.utm_placement || "";
    this.Gclid = this.$route.query.gclid || "";
  },
  updated() {
    console.log(this.selectedProgram);
  },

  methods: {
    onStateChange() {
      const state = this.stateList.find((s) => s.state === this.selectedState);
      this.cities = state ? state.districts : [];
      this.selectedCity = "";
    },

    validateInput(event) {
      const regex = /^[A-Za-z\s]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^A-Za-z\s]/g, "");
      }
      this.name = event.target.value;
    },

    sanitizeInput() {
      // Remove non-numeric characters
      this.enteredOtp = this.enteredOtp.replace(/[^0-9]/g, "");
    },

    onPhoneNumberChange() {
      // Reset the error message when the phone number changes
      this.message = "";

      // Reset otpSent when the phone number changes
      this.otpSent = false;

      // Remove non-numeric characters from the phone number
      this.phoneNumber = this.phoneNumber.replace(/\D/g, "");

      // Limit the phone number to a maximum length of 10 digits
      if (this.phoneNumber.length < 10) {
        this.phoneNumber = this.phoneNumber.slice(0, 10);
      }
    },

    handleKeyPress(event) {
      // Allow only numeric input and backspace
      if (
        !(event.charCode >= 48 && event.charCode <= 57) &&
        event.charCode !== 8
      ) {
        event.preventDefault();
      }
    },

    async enquireSubmit(el) {
      try {
        console.log("enquireSubmit");
        if (this.phoneNumber.length < 10) {
          this.phoneNumberError = "Phone number must be exactly 10 digits.";
          return;
        }

        const dataJson = [
          {
            Attribute: "FirstName",
            Value: el.target.elements.name.value,
          },
          {
            Attribute: "EmailAddress",
            Value: el.target.elements.email.value,
          },
          {
            Attribute: "Phone",
            Value: this.selectedCountryCode + this.phoneNumber,
          },
          {
            Attribute: "mx_State",
            Value: el.target.elements.state.value,
          },
          {
            Attribute: "mx_City",
            Value: el.target.elements.city.value,
          },
          {
            Attribute: "mx_Program",
            Value: el.target.elements.program.value,
          },
          {
            Attribute: "mx_Elective",
            Value: el.target.elements.elective.value,
          },
          {
            Attribute: "alumni_enrollment_number",
            Value: el.target.elements.enlname.value,
          },

          {
            Attribute: "mx_utm_Source",
            Value: "DB_Engagement",
          },
          {
            Attribute: "SourceCampaign",
            Value: "Alumni",
          },
          {
            Attribute: "SourceMedium",
            Value: "LP",
          },
          {
            Attribute: "mx_utm_Adgroupid",
            Value: this.utmAdgroupid,
          },
          {
            Attribute: "mx_utm_Campaignid",
            Value: this.utmCampaignid,
          },
          {
            Attribute: "mx_utm_Creativeid",
            Value: "this.utmCreativeid",
          },
          {
            Attribute: "mx_utm_Device",
            Value: this.utmDevice,
          },
          {
            Attribute: "mx_utm_Keyword",
            Value: this.utmKeyword,
          },
          {
            Attribute: "mx_utm_Matchtype",
            Value: this.utmMatchtype,
          },
          {
            Attribute: "mx_utm_Network",
            Value: this.utmNetwork,
          },
          {
            Attribute: "mx_utm_Placement",
            Value: this.utmPlacement,
          },
          {
            Attribute: "mx_Gclid",
            Value: this.Gclid,
          },
        ];

        console.log("data", dataJson);

        await axios
          .post("https://api.vignanonline.com/api/capture-lead/", dataJson)
          .then((resp) => {
            console.log(resp, "this is resp");
            if (resp.data.external_response.Status == "Success") {
              el.target.reset();
              window.location.href = `/lp/thankyou/alumni`;
            }
          })
          .catch((error) => {
            console.log(error.response.data.ExceptionMessage);
            this.message = error.response.data.ExceptionMessage;
            if (this.message) {
              this.showForm = false;
            }
          });
      } catch (error) {
        console.error("Error:", error);
        this.errorMessage = "An error occurred. Please try again later.";
      }
    },
    openPopup() {
      this.visible = true;
    },
    closePopup() {
      this.visible = false;
    },
    closeModal() {
      this.showForm = true;
    },

    updateElectiveOptions() {
      // Define elective options based on the selected program
      if (this.selectedProgram === "MBA") {
        this.electiveOptions = [
          { value: "Finance", label: "Finance" },
          { value: "Marketing", label: "Marketing" },
          {
            value: "Human Resource Management",
            label: "Human Resource Management",
          },
          { value: " Business Analytics", label: "Business Analytics" },

          {
            value: "Finance and Human Resource Management",
            label: "Finance and Human Resource Management",
          },
          { value: "Information Technology", label: "Information Technology" },

          {
            value: "Healthcare and Hospital Management",
            label: "Healthcare and Hospital Management",
          },
          // {
          //   value: " Logistics and Supply Chain Management",
          //   label: " Logistics and Supply Chain Management",
          // },
          // { value: "Operations Management", label: "Operations Management" },
        ];
      } else if (this.selectedProgram === "MCA") {
        this.electiveOptions = [
          {
            value: "Computer Science and IT",
            label: "Computer Science and IT",
          },
          { value: "Data Science", label: "Data Science" },
        ];
      } else if (this.selectedProgram === "BCA") {
        this.electiveOptions = [
          {
            value: "Computer Science and IT",
            label: "Computer Science and IT",
          },
        ];
      } else if (this.selectedProgram === "BBA") {
        this.electiveOptions = [{ value: "General", label: "General" }];
      }
    },
  },
};
</script>
  <style lang=""></style>
  