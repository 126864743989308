import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "jquery";
import { createHead } from "@unhead/vue";
import VueGtag from "vue-gtag";
// import VueLazyload from 'vue-lazyload'

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faInstagram,
  faYoutube,
  faSquareFacebook,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCircleChevronDown,
  faCircleChevronUp,
  faAngleDown,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
const head = createHead();
createApp(App).use(store).use(router).use(head).mount("#app");
/* add icons to the library */
library.add(
  faInstagram,
  faYoutube,
  faSquareFacebook,
  faLinkedin,
  faXTwitter,
  faCircleChevronDown,
  faCircleChevronUp,
  faAngleDown,
  faChevronDown
);
const app = createApp(App);

// app.use(VueGtag, {
//   config: { id: "GTM-N5X98VM" },
// });
// let globe = [
//   "/lp/online-ug-degree-program.html",
//   "/lp/thankyou/online-pg-degree-program.html",
// ];

// if (!globe.includes(window.location.pathname)) {
//   app.use(VueGtag, {
//     config: { id: "GTM-N5X98VM" },
//   });
// }
// // config: { id: "AW-11303512183" }, old Gtag
// let configureGTM = [
//   "/lp/online-degree-program.html",
//   "/lp/thankyou/online-degree-program.html",
// ];
// if (configureGTM.includes(window.location.pathname)) {
//   app.use(VueGtag, {
//     includes: [{ id: "GTM-PJFRD4CG" }],
//     config: { id: "GTM-PJFRD4CG" },
//   });
// }


let puneThankyouPage = [
  "/lp/online-mba-program.html",
  "/lp/online-mca-program.html",
  // "/lp/online-bba-program.html",
  "/lp/online-mca-degree-program.html",
  "/lp/online-bca-degree-program.html",
];
if (puneThankyouPage.includes(window.location.pathname)) {
  app.use(VueGtag, {
    config: { id: "GTM-N5X98VM" },
  });
  
}

// Vue.use(VueLazyload);

// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   attempt: 1
// }),

app
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(createHead())
  .mount("#app");
