<template>
  <div class="max-w-7xl m-auto">
    <!--desktop design-->
    <div class="hidden lg:block my-4">
      <div class="text-3xl font-semibold py-4">Online Degree Programs Offered</div>
      <div class="flex gap-x-4 justify-center my-4">
        <div v-for="el in courses" :key="el.id">
          <div class="w-[9rem] xl:w-[11rem]">
            <img :src="el.image" alt="images" />
          </div>
        </div>
      </div>
      <div  @click="openModal">
      <div
        class="bg-[#c82228] text-white w-max py-2 px-8 m-auto rounded-full font-semibold text-2xl cursor-pointer mt-12"
      >
        Enquire Now
      </div>
    </div>
    </div>
    <!--desktop design-->
    <!--Mobile design-->
    <div class="lg:hidden">
      <div class="text-xl my-4 w-[15rem] m-auto font-bold">
        Online Degree Programs Offered
      </div>
      <div class="slick_mob_degree items-center justify-center">
        <div class="" v-for="el in courses" :key="el.id">
          <div class=" mx-auto py-4">
            <div class="w-max m-auto">
              <img :src="el.image" alt="course images" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Mobile design-->
    <div class="Emodal" v-if="isModalOpen">
      <div
        class="Emodal-content z-50 rounded-md relative bottom-2 w-[90%] md:w-[35%] 2xl:w-[20%]"
      >
        <span class="close" @click="closeModal">&times;</span>
        <EnquireAlumni />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import EnquireAlumni from "@/components/LPEnquire/EnquireAlumni.vue";
import "slick-carousel";
export default {
  name: "onlineDegree",
  components: {
    EnquireAlumni,
  },
  data() {
    return {
      isOpen: false,
      isModalOpen: false,
      courses: [
        {
          id: 1,
          image: require("@/assets/alumni-lp/MBA.webp"),
        },
        {
          id: 2,
          image: require("@/assets/alumni-lp/MCA.webp"),
        },
        // {
        //   id: 3,
        //   image: require("@/assets/alumni-lp/BBA.webp"),
        // },
        // {
        //   id: 4,
        //   image: require("@/assets/alumni-lp/BCA.webp"),
        // },
     
    
 
      ],
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },

  mounted() {
    $(`.slick_mob_degree`).slick({
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      appendDots: $('.slick_mob_degree'),
      prevArrow: false,
      nextArrow: false,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  },
};
</script>

<style scoped>
.Emodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.Emodal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.close {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 0px;
  right: 5px;
}
</style>
